<template>
    <div class="page bg-white">
        <el-table
                :data="dataList"
                v-loading="loading"
                size="small"
                height="calc(100% - 50px)"
                class="table">
            <el-table-column prop="userName" label="用户姓名" show-overflow-tooltip></el-table-column>
            <el-table-column prop="userEmail" label="邮箱" show-overflow-tooltip></el-table-column>
            <el-table-column prop="userPhone" label="电话" show-overflow-tooltip></el-table-column>
            <el-table-column prop="userReamke" label="留言" show-overflow-tooltip></el-table-column>
            <el-table-column prop="createTime" label="创建时间" show-overflow-tooltip></el-table-column>
        </el-table>
        <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageNo"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :total="total"
                background=""
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                dataList: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                loading: false
            }
        },
        activated() {
            this.refreshList()
        },
        methods: {
            // 获取数据列表
            refreshList() {
                this.loading = true
                this.$axios(this.api.order.contact, {
                    'current': this.pageNo,
                    'size': this.pageSize,
                }, 'get').then(data => {
                    if (data.status) {
                        this.dataList = data.data.records
                        this.total = parseInt(data.data.total)
                        this.loading = false
                    }
                })
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.refreshList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.refreshList()
            },

        }
    }
</script>
